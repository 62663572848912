<template>
  <sm-report :reportName="reportName" :breadcrumbs="breadcrumbs" />
</template>

<script>
import SmReport from '@/components/views/SmReport.vue';

export default {
  name: 'TelemarketingCheckListReport',

  components: {
    SmReport,
  },

  data() {
    return {
      reportName: 'TelemarketingCheckListReport',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Отчет по чек-листам телемаркетинга',
        },
      ],
    };
  },
};
</script>
